import * as React from 'react';
import * as Klaro from 'klaro/dist/klaro-no-css';
import './klaro.css';

class KlaroCookieWindow extends React.Component {

    static KLARO_CONFIGURATION = {
        // You can customize the ID of the DIV element that Klaro will create
        // when starting up. If undefined, Klaro will use 'klaro'.
        elementID: 'klaro',
    
        // How Klaro should store the user's preferences. It can be either 'cookie'
        // (the default) or 'localStorage'.
        storageMethod: 'cookie',
    
        // You can customize the name of the cookie that Klaro uses for storing
        // user consent decisions. If undefined, Klaro will use 'klaro'.
        cookieName: 'klaro_session',
    
        // You can also set a custom expiration time for the Klaro cookie.
        // By default, it will expire after 120 days.
        cookieExpiresAfterDays: 120,
    
        // You can change to cookie domain for the consent manager itself.
        // Use this if you want to get consent once for multiple matching domains.
        // If undefined, Klaro will use the current domain.
        //cookieDomain: '.github.com',
    
        // Put a link to your privacy policy here (relative or absolute).
        privacyPolicy: `https://www.youthquake.it/it/privacy-policy`,
    
        // Defines the default state for applications (true=enabled by default).
        default: true,
    
        // If "mustConsent" is set to true, Klaro will directly display the consent
        // manager modal and not allow the user to close it before having actively
        // consented or declines the use of third-party apps.
        mustConsent: false,
    
        // Show "accept all" to accept all apps instead of "ok" that only accepts
        // required and "default: true" apps
        acceptAll: true,
    
        // replace "decline" with cookie manager modal
        hideDeclineAll: false,
    
        // You can define the UI language directly here. If undefined, Klaro will
        // use the value given in the global "lang" variable. If that does
        // not exist, it will use the value given in the "lang" attribute of your
        // HTML tag. If that also doesn't exist, it will use 'en'.
        lang: 'it',

        // Setting this to true will render the descriptions of the consent
        // modal and consent notice are HTML. Use with care.
        htmlTexts: true,
    
        // You can overwrite existing translations and add translations for your
        // app descriptions and purposes. See `src/translations/` for a full
        // list of translations that can be overwritten:
        // https://github.com/KIProtect/klaro/tree/master/src/translations
    
        // Example config that shows how to overwrite translations:
        // https://github.com/KIProtect/klaro/blob/master/src/configs/i18n.js
        translations: {
            it: {
                consentNotice: {
                    description: '<img width="26" height="26" src="https://www.youthquake.it/media/cookie.svg" alt="cookie icon" style="width: 26px; display: block; margin-bottom: 10px;">Ciao! Il nostro sito utilizza cookie tecnici e analitici. In parole semplici, il cookie è un piccolo file di testo contenente informazioni utili ad analizzare i modelli di traffico del nostro sito. Non contiene gocce di cioccolato, non si può mangiare e non c\'è un barattolo speciale nascosto. Utilizziamo anche cookie di profilazione di terze parti per le quali è necessario il tuo consenso. Adesso hai tutte le informazioni necessarie per cliccare su “Accetta” e dare il consenso, se invece vuoi evitare i cookie di profilazione seleziona “Rifiuta”. Il pulsante “Personalizza”, infine, ti permette di scegliere manualmente quali cookie accettare. Trovi ulteriori dettagli all’interno della <a href=\'/it/cookie-policy\'>cookie policy</a>.',
                    learnMore: 'Personalizza'
                },
                ok: 'Accetta',
                decline: 'Rifiuta',
                consentModal: {
                    description: "Proseguendo la navigazione senza esprimere una scelta acconsenti all'uso dei cookie tecnici. Questo sito può utilizzare anche cookie di profilazione di terze parti per inviarti messaggi pubblicitari mirati in linea con le tue preferenze. Se acconsenti clicca su 'Accetta tutti', se invece vuoi negare il consenso a tutti o ad alcuni cookie modifica le impostazioni qui in basso e clicca su 'Accetta selezionati'.",
                    privacyPolicy: {
                        name: 'Informativa Privacy',
                        text: "Per saperne di più, leggi la nostra {privacyPolicy}, al cui interno troverai il link alla nostra policy sui Cookies."
                    }
                },
                service: {
                    purpose: 'Finalità'
                },
                google: {
                    title: 'Google (Non anonimizzato)',
                    description: 'Cookie di profilazione con finalità analitiche e di profilazione.'
                },
                googleAnonymous: {
                    title: 'Google (Anonimizzato)',
                    description: 'Cookie tecnico con finalità analitiche.'
                },
                facebook: {
                    title: 'Facebook',
                    description: 'Cookie di profilazione con finalità analitiche e di profilazione.'
                },
                purposes: {
                    profilazione: 'Profilazione',
                    analitico: 'Analitico'
                }
            },
            en: {
                consentNotice: {
                    description: '<img src="https://www.youthquake.it/media/cookie.svg" alt="cookie icon" style="width: 26px; display: block; margin-bottom: 10px;">Hi! Our website uses technical and analytic cookies. Simply put, a cookie is a smalltext file containing information that is useful for analyzing our website\'s traffic patterns. It does not contain chocolate chips, you cannot eat it and there is no special hidden jar.We also use third-party profiling cookies for which your consent is required. Now you have all the information you need to click \'Accept\' and give your consent, otherwise if you want to avoid profiling cookies select \'Deny\'. Last but not least, the \'Customize\' button allows you to manually choose which cookies to accept. You can find more details in our <a href=\'/en/cookie-policy\'>cookie policy</a>.',
                    learnMore: 'Customize'
                },
                ok: 'Accept',
                decline: 'Deny',
                google: {
                    title: 'Google (Not Anonymized)',
                    description: 'Profiling cookies with analytical and profiling purposes.'
                },
                googleAnonymous: {
                    title: 'Google (Anonymized)',
                    description: 'Technical cookie with analytical purposes.'
                },
                facebook: {
                    title: 'Facebook',
                    description: 'Profiling cookies with analytical and profiling purposes.'
                },
                purposes: {
                    profilazione: 'Profiling',
                    analitico: 'Analytical'
                }
            }
        },
    
        // This is a list of third-party apps that Klaro will manage for you.
        services: [
            {
                name: 'google',
                default: true,
                purposes: ['analitico', 'profilazione'],
                cookies: [['__utma', '/', '.youthquake.it'], ['__utmb', '/', '.youthquake.it'], ['__utmc', '/', '.youthquake.it'],
                    ['__utmt', '/', '.youthquake.it'], ['__utmz', '/', '.youthquake.it'], ['_ga', '/', '.youthquake.it'],
                    ['_gid', '/', '.youthquake.it'], [/^_gat.*$/i, '/', '.youthquake.it'], [/^_gcl.*$/i, '/', '.youthquake.it']],
            },
            {
                name: 'facebook',
                default: true,
                purposes: ['analitico', 'profilazione'],
                cookies: [['fr', '/', '.youthquake.it'], ['tr', '/', '.youthquake.it'], ['_fbp', '/', '.youthquake.it']],
            },
            {
                name: 'googleAnonymous',
                required: true,
                purposes: ['analitico',]
            }
        ]
    };

    constructor(props){
        super(props);
        this.setupKlaroInWindow();
    }

    render(){
        return null;
    }

    setupKlaroInWindow(){
        const w = window;
        w.klaro = Klaro;
        w.klaroConfig = KlaroCookieWindow.KLARO_CONFIGURATION;
        Klaro.setup(KlaroCookieWindow.KLARO_CONFIGURATION);
    }

}

export default KlaroCookieWindow;