/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect} from 'react';
import { ThemeProvider } from 'styled-components';
import { GlobalContext } from '../hooks/context/Context';

const theme_0 = {
    colors: {
        main: '#16774A',
        secondary: '#CCFF00'
    }
}

const theme_1 = {
    colors: {
        main: '#333333',
        secondary: '#00FF5D'
    }
}

const theme_2 = {
    colors: {
        main: '#45003D',
        secondary: '#FF00F4'
    }
}

const theme_3 = {
    colors: {
        main: '#001245',
        secondary: '#0053FF'
    }
}

const theme_4 = {
    colors: {
        main: '#004545',
        secondary: '#00FDFF'
    }
}

const theme_5 = {
    colors: {
        main: '#452E00',
        secondary: '#FFD700'
    }
}

const theme_6 = {
    colors: {
        main: '#450000',
        secondary: '#E30B5C'
    }
}

const theme_7 = {
    colors: {
        main: '#001A45',
        secondary: '#8f00ff'
    }
}

const theme_8 = {
    colors: {
        main: '#450012',
        secondary: '#FAF0E6'
    }
}

function Theme ({children}) {
    const context = useContext(GlobalContext)

    useEffect(() => {
        handleTheme()
    }, [context.carousel.state.count])

    function handleTheme() {
        switch (context.carousel.state.count) {
            case 0:
                return theme_0
            case 1:
                return theme_1
            case 2:
                return theme_2
            case 3:
                return theme_3
            case 4:
                return theme_4
            case 5:
                return theme_5
            case 6:
                return theme_6
            case 7:
                return theme_7
            case 8:
                return theme_8
            default:
                return theme_0
        }
    }

    return <ThemeProvider theme={handleTheme}>{children}</ThemeProvider>;
};

export default Theme;