/* eslint-disable no-sparse-arrays */
/* eslint-disable import/no-anonymous-default-export */
export default {
    TITLE_PAGE: 'Non rinneghiamo il nostro passato per evolvere costantemente il nostro presente',

    ROLES: [
        {
            TITLE: "UX DESIGNER",
            TEXT: "L'esperienza dell'utente (UX) è come una persona si sente quando interagisce con un prodotto o servizio, che sia un sito web, un'applicazione web o un prodotto Gli UX stanno attenti a cose come la facilità d'uso, la percezione del valore, l'utilità e l'efficienza nell'eseguire i compiti Analizzano i dati, organizzano le informazioni, comprendono il comportamento umano in relazione ai loro progetti e creano esperienze interattive che attirano gli utenti e li fanno tornare I grandi UX designer pensano, in modo tale che non siano i loro utenti a doverlo far.",
            SKILL_NEEDED: [
                "Espressione visiva/comunicazione",
                "Pensiero analitico",
                "Padronanza del design dell'informazione",
                "Tecniche di ricerca",
                "Gestione del progetto",
                "Modellazione degli utenti",
                "Scrittura tecnica",
                "Conoscenza dei principi del test di usabilità"
            ]
        },
        {
            TITLE: "UI DESIGNER",
            TEXT: "L’User Interface Designer prende la tecnologia e la rende fresca, facile da usare e amichevole per il consumatore Il ruolo richiede una comprensione del comportamento umano in quanto l'UI Designer lavora per determinare come i consumatori interagiranno con la tecnologia in qualsiasi forma essa sia: un'app, un sito web, un'auto o anche una televisione <br /> <br />Gli UI Designer capiscono i passi che i consumatori useranno quando accedono alla tecnologia, poi progettano modelli che accorciano o snelliscono i passi nel processo per creare una migliore esperienza utente.",
            SKILL_NEEDED: [
                "Conoscenza di programmi informatici nelle aree del design grafico, dell'architettura dell'informazione e dell'interaction design",
                "Familiarità con le ricerche di marketing dei consumatori",
                "Comprensione nell'equilibrio dei colori, delle texture e degli elementi interattivi (come i componenti del design di navigazione) che rendono una pagina web user-friendly.",
                "Grande comunicatore e paziente quando lavora in team",
                "Creativi ma anche scientifici e organizzati",
                "Grande attenzione ai dettagli"
            ]
        },
        {
            TITLE: "GRAPHIC DESIGNER",
            TEXT: "L'arte ti fa sentire qualcosa; il design ti fa fare qualcosa Un Graphic Designer combina gli standard del marchio, la teoria del colore, gli elementi grafici e la tipografia per far fare entrambe le cose al suo pubblico Il Graphic Designer crea concetti visivi di testo e immagini, per comunicare idee che ispirano, informano o catturano i consumatori.",
            SKILL_NEEDED: [
                'Conoscenza delle tecniche, degli strumenti e dei principi di progettazione',
                'Conoscenza della tipografia',
                'Sviluppo di contenuti multimediali',
                'Tecniche di produzione, comunicazione e diffusione dei media',
                'Comprensione delle capacità dei browser',
                'Creatività e originalità',
                'Fluidità di idee',
                'Attenzione ai dettagli',
                'Adattabilità, flessibilità',
                'Comunicazione con il cliente',
                'Lavoro di squadra',
                'Organizzazione, capacità di destreggiarsi tra più progetti',
            ]
        },
        {
            TITLE: "FRONT-END DEV",
            TEXT: "Un Front End Developer (FED) ha bisogno di avvicinarsi al design del sito web con una visione creativa e tecnica - un po' di Jobs, un po' di Wozniak Il credo di un FED dovrebbe essere: 'Mi impegno per la bellezza e la funzionalità che supererà le aspettative del nostro utente finale'Se questo appare ai tuoi occhi come marmellata, allora continua a leggere!<br /><br />Oltre ad essere un pensatore creativo, un FED deve essere radicato nella fluidità tecnica e deve possedere un livello di padronanza sui meccanismi interni del design del sito Un FED è tenuto a sviluppare una precisa categorizzazione dei contenuti e a creare una navigazione del sito che incanali il traffico attraverso il contenuto principale del sito.",
            SKILL_NEEDED: [
                "Design grafico per il web",
                "Interfaccia grafica utente (UI)",
                "Esperienza di programmazione web",
                "Sviluppo Front End",
                "HTML/CSS",
                "Progettazione dell'interfaccia utente web",
                "Competenze di programmazione web",
                "Strumenti di illustrazione",
                "Sviluppo di contenuti multimediali",
                "Comprensione delle capacità del browser",
                "Comunicazione con il cliente",
                "Lavoro di squadra",
                "Organizzazione, giocoleria su più progetti",
                "Capacità di autogestione"
            ]
        },
        {
            TITLE: "BACK-END DEV",
            TEXT: "Il lavoro di Back End Web Developer è tutto incentrato sul dettaglio minuzioso che si trova nelle utilità del software Il Back End Developer è uno specialista del codice sorgente, all'inizio del processo di progettazione di un sito web personalizzato, ed è fondamentale per far decollare un progetto web Se navighi su un sito web, non vedrai il lavoro di questo specialista, ma non saresti ingrado di accedere al sito web senza il loro lavoro.",
            SKILL_NEEDED: [
                "Possedere un'estrema pazienza, poiché scrivono codice, lo testano e poi scrivono altro codice per il debug",
                "Sono pensatori logici e organizzati, metodici e matematici fino al midollo",
                "Devono essere a loro agio con l'architettura tecnologica e come funziona un computer e il suo software",
                "Sono guidati dalle scadenze, organizzati e capiscono come le persone vedono e comprendono il contenuto sul front-end",
                "Lo sviluppatore back-end vive nel mondo dei morsi e dei bit, scrivendo schermate di codici che lo spettatore medio semplicemente non capirebbe",
            ]
        },
        {
            TITLE: "3D DESIGNER",
            TEXT: "Se avete visto Gli Incredibili 2 o vi siete goduti il giro di Harry Potter alla Universal, avete visto il lavoro dei 3D Designers Un 3D Designer può portare invita la nostra immaginazione più selvaggia o i nostri incubi più spaventosi <br /><br /> In YOUTHQUAKE, il 3D Designer crea forme, personaggi ed effetti visivi sui principali social media, siti web e molto altro, aggiungendo paesaggi per crearemondi e fisicità che danno vita anche alle creazioni più strane.",
            SKILL_NEEDED: [
                "Intellettualmente curioso del mondo e di come le cose (persone, macchine, nuvole, insetti) simuovono e funzionano",
                "Fortemente focalizzato visivamente e capace di vedere sottili sfumature nei colori e nelle texture e ricrearle nella loro arte.",
                "Altamente creativo con una stellare attitudine artistica per il disegno",
                "Eccellenti competenze informatiche",
                "Capacità di fotografia digitale",
                "Forte sensibilità per l'equilibrio nei layout, il colore e altri principi grafici importanti per il design grafico",
                "Senso dell'umorismo",
                "Buone capacità di editing"
            ]
        },
        {
            TITLE: "COPYWRITER",
            TEXT: "Tweets, siti web, intere campagne pubblicitarie un copywriter le scrive tutte Traducono gli obiettivi di marketing in campagne accattivanti che ispirano l'azione Copy, contenuto e concetti sono tre delle loro cose preferite! Il loro compito principale è quello di comunicare con i clienti nei loro termini e al loro livello in modo che il messaggio di vendita dell'inserzionista sia prontamente ricevuto Devono anche essere in grado di capire il prodotto di un cliente, avere conoscenza dei temi/metodi pubblicitari.",
            SKILL_NEEDED: [
                "Eccellente competenza linguistica",
                "Conoscenza del significato e l'ortografia delle parole, le regole di composizione e la grammatica",
                "Abilità comunicative superiori alla media",
                "Conoscenza delle tecniche e dei metodi di produzione, comunicazione e diffusione dei media",
                "Tecniche di vendita e marketing",
                "Capacità di fornire e conoscenza dei principi e dei processi per fornire servizi al cliente e alla persona"
            ]
        },
        {
            TITLE: "SOCIAL MEDIA",
            TEXT: "Social Media Specialist lavora con il dipartimento di marketing per aumentare la presenza di un'azienda sui social media Quando si scrive una descrizione del lavoro di Social Media Specialist, tenere a mente quanto segue: i social media specialist devono avere familiarità con una varietà di luoghi di marketing interattivo Li troverete incollati alla rete, sul loro telefono, laptop e tablet Devono essere in grado di cercare informazioni in rete e poi trasformarle in una prosa scattante che si adatti alle limitazioni di una varietà di piattaforme.",
            SKILL_NEEDED: [
                "Esperto di tecnologia e social media",
                "Capire l'ottimizzazione dei motori di ricerca (SEO)",
                "I social media manager sanno come costruire un pubblico, come raggiungerlo e come monitorare i risultati di un post sui social media",
                "multitasking all'estremo: saper gestire diversi flussi social per singoli clienti contemporaneamente"
            ]
        },
        {
            TITLE: "DATA ANALYST",
            TEXT: "Gli analisti di dati sono tutti interessati ai numeri Nello stesso modo in cui un archeologo setaccia ilterreno per arrivare al fossile al suo interno, gli analisti di dati aziendali setacciano le informazioni statistiche per capire il loro significato in un particolare campo L'analista di dati raccoglie i dati, li ordina in categorie e poi li interpreta Il loro lavoro costituisce la base per le decisioni strategiche che influenzano il mondo in cui viviamo ogni giorno.",
            SKILL_NEEDED: [
                "Capacità di catturare i significati dietro un gruppo di numeri L'analista di dati capisce il perché dietro i numeri su uno schermo",
                "Le competenze informatiche, unite al pensiero critico, sono componenti importanti di questoruolo",
                "L'analisi statistica è la chiave di questa professione",
                "Capacità di sintesi dei risultati",
                "Pazienza con lunghe stringhe di dati su un foglio Excel",
                "Competenza tecnica con una varietà di strumenti software è un grande requisito di questo lavoro"
            ]
        }
    ]
};