import React from 'react'
import styled from 'styled-components'

const Logo = styled('div')`
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 2;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    background: ${({theme}) => theme.colors.secondary};
    color: ${({theme}) => theme.colors.main};
    text-decoration: none;

    &::after, &:after {
        color: ${({theme}) => theme.colors.secondary};
        content: 'Internship';
        position: fixed;
        left: 70px;
    }
`;

export default function Header() {
    return (
        <a href="https://www.youthquake.it/" rel="noopener noreferrer">
            <Logo>
                YQ
            </Logo>
        </a>
    )
}
