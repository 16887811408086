import React, {useEffect} from 'react'
import styled from 'styled-components'
import gsap from 'gsap'

const Wrapper = styled('div')`
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;

    @media ${'(max-width: 768px)'} {
        display: none;
    }

    img {
        max-width: 100px;
        pointer-events: none;
        margin-left: -50%;
        margin-top: 40px;
        opacity: .99;
    }
`;

export default function FollowMouse() {
    useEffect(() => {
        handleFollowMouse()
        handleModalButtonMouseHover()
    },)
    return (
        <Wrapper>
            <img id={'banana'} src={`${process.env.PUBLIC_URL}/media/banana.png`} alt="banana" />
        </Wrapper>
    )

    function handleModalButtonMouseHover(){
        const buttonModal = document.querySelector('#btn-wrapper');
        buttonModal.addEventListener('mouseenter', e => {
            gsap.to('#banana', .4, {y: window.innerHeight, scale: 0})
        })

        buttonModal.addEventListener('mouseleave', e => {
            gsap.to('#banana', .4, {y: 0, scale: 1})
        })
    }

    function handleFollowMouse(){
        const banana = document.querySelector('#banana');
        const pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
        const mouse = { x: pos.x, y: pos.y };
        const speed = 0.35;

        const xSet = gsap.quickSetter(banana, "x", "px");
        const ySet = gsap.quickSetter(banana, "y", "px");
        const XRotate = gsap.quickSetter(banana, "rotate", "deg");

        window.addEventListener("mousemove", e => {    
            mouse.x = e.x;
            mouse.y = e.y;
        });

        gsap.ticker.add(() => {
            const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio()); 
            
            pos.x += (mouse.x - pos.x) * dt;
            pos.y += (mouse.y - pos.y) * dt;
            xSet(pos.x);
            ySet(pos.y);
            XRotate( -(pos.x - pos.y) / 50 );
        });
    }
}
