import React, {useContext} from 'react'
import styled from 'styled-components'
import Constants from '../config/Constants'
import { GlobalContext } from '../hooks/context/Context'
import ButtonCarousel from '../components/ButtonCarousel/ButtonCarousel'

const Wrapper = styled('div')`
    width: 100%;
    height: 100vh;
    background: ${({theme}) => theme.colors.main};
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
`
const WrapperText = styled('div')`
    width: 100%;
    max-width: 300px;
    left: 30px;
    bottom: 220px;
    position: absolute;
    transform: rotate(-90deg) translateY(-165px);
    @media ${'(max-width: 768px)'} {
        display: none;
    }
`;
const Text = styled('div')`
    position: absolute;
    font-size: 14px;
    color: ${({theme}) => theme.colors.secondary};
    text-align: left;
    width: fit-content;
    letter-spacing: 0.01em;
`
const Title = styled('span')`
    width: 100%;
    height: 100vh;
    font-size: 25vw;
    color: ${({theme}) => theme.colors.secondary};
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-15deg);
    pointer-events: none;
    user-select: none;
`;

export default function PageHome() {
    const context = useContext(GlobalContext)

    return (
        <Wrapper>
            {renderTextAniamated()}
            <Title className='f-m'>
                {Constants.ROLES[context.carousel.state.count].TITLE}
            </Title>
            <ButtonCarousel/>
        </Wrapper>
    )

    function renderTextAniamated(){
        return <WrapperText>
            <Text id='title'>{Constants.TITLE_PAGE}</Text>
        </WrapperText>
    }
}
