import React, {useContext} from 'react'
import styled, {keyframes} from 'styled-components'
import { GlobalContext } from '../../hooks/context/Context';

const rotateOr = keyframes`
    to {
        transform: rotate(360deg);
    }
`;

const rotateAn = keyframes`
    to {
        transform: rotate(-360deg);
    }
`;

const rotateHover = keyframes`
    from {
        transform: rotate(0deg) scale(1.1);
    }
    to {
        transform: rotate(-360deg) scale(1.1);
    }
`;

const WrapperControls = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 5;
    transform: rotate(-15deg);

    @media ${'(max-width: 768px)'} {
        transform: rotate(0deg);
    }

    span {
        color: ${({theme}) => theme.colors.secondary};
        font-size: 13px;
        font-weight: 500;
        cursor: pointer;
        &:first-child {
            margin-right: 100px;
            &:after, &:after {
                left: 50px;
            }
        }
        &:last-child {
            margin-left: 100px;
            &:after, &:after {
                right: 50px;
            }
        }

        &:after, &:after {
            content: '';
            position: absolute;
            top: 50%;
            width: 30px;
            height: 1px;
            background: ${({theme}) => theme.colors.secondary};
        }
    }
`;

const WrapperButton = styled('div')`
    position: absolute;
    top: 25vh;
    left: 190px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: all 300ms ease-in-out;
    z-index: 10;

    @media ${'(max-width: 768px)'} {
        bottom: -40vh;
        left: 50vw;
    }

    svg {
        position: absolute;
        
        &:first-child {
            max-width: 80px;
            animation: ${rotateOr} 20s linear infinite;
        }
        &:last-child {
            max-width: 120px;
            animation: ${rotateAn} 30s linear infinite;
        }

        &:hover{
            animation: ${rotateHover} 6s linear infinite;
        }

        #YQ---LP {
            fill: ${({theme}) => theme.colors.secondary};
            stroke: ${({theme}) => theme.colors.secondary};
        }
    }

    span.f-m {
        position: absolute;
        z-index: 10;
        font-size: 3em;
        letter-spacing: 0.08em;
        line-height: 0;
        margin-bottom: -6px;
        color: ${({theme}) => theme.colors.main}
    }
`;

export default function ButtonCarousel() {
    const context = useContext(GlobalContext);
    return (
        <WrapperButton>
            {renderItemSolid()}
            {renderControls()}
            {renderText()}
            {renderItemStroke()}
        </WrapperButton>
    )

    function renderItemSolid(){
        return (
            <svg width="118px" height="122px" viewBox="0 0 118 122" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="YQ---LP" transform="translate(-1685.000000, -3438.000000)">
                        <path d="M1744,3438.52573 L1779.54588,3450.07529 L1801.51445,3480.31242 L1801.51445,3517.68758 L1779.54588,3547.92471 L1744,3559.47427 L1708.45412,3547.92471 L1686.48555,3517.68758 L1686.48555,3480.31242 L1708.45412,3450.07529 L1744,3438.52573 Z" id="Polygon"></path>
                    </g>
                </g>
            </svg>
        )
    }

    function renderItemStroke(){
        return (
            <svg width="158px" height="154px" viewBox="0 0 158 154" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="yq--" transform="translate(-1665.000000, -3422.000000)" stroke="#fff">
                        <path d="M1744,3420.52573 L1790.12602,3435.51298 L1818.63346,3474.75012 L1818.63346,3523.24988 L1790.12602,3562.48702 L1744,3577.47427 L1697.87398,3562.48702 L1669.36654,3523.24988 L1669.36654,3474.75012 L1697.87398,3435.51298 L1744,3420.52573 Z" id="Polygon" transform="translate(1744.000000, 3499.000000) rotate(-345.000000) translate(-1744.000000, -3499.000000) "></path>
                    </g>
                </g>
            </svg>
        )
    }
    function renderText(){
        const {count} = context.carousel.state
        return (
            <span className='f-m' style={{'userPointer': 'none', userSelect: 'none'}}>
                {`0${count + 1}`}
            </span>
        )
    }

    function renderControls(){
        return (
            <WrapperControls>
                <span onClick={(e) => handlePrev(e)}>PREV</span>
                <span onClick={(e) => handleNext(e)}>NEXT</span>
            </WrapperControls>
        )
    }

    function handleNext(e){
        e.preventDefault()
        context.carousel.carouselDispatch({type: 'NEXT'})
    }

    function handlePrev(e){
        e.preventDefault()
        context.carousel.carouselDispatch({type: 'PREV'})
    }
}
