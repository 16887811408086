import Constants from "../../config/Constants";

export const initialStateCarousel = {
    count: 0
};

const CarouselReducer = (state, action) => {
    switch (action.type) {
        case 'NEXT':
            return { ...state, count: state.count >= Constants.ROLES.length -1 ? 0 : state.count+1};
        case 'PREV':
            return { ...state, count: state.count < 1 ? Constants.ROLES.length-1 : state.count-1};
        case 'RESET_COUNTER':
            return { ...state, count: 0 };
        default:
            return state;
    }
};

export default CarouselReducer;