import React, { Suspense } from 'react'
import { Canvas } from '@react-three/fiber'
import { Suzi } from './Scene'
import { Environment, Loader, OrbitControls} from '@react-three/drei'

export default function CanvasScene() {

    return (
        <>
            <Canvas dpr={[1, 2]} camera={{ position: [0, 0, 2.6] }} gl={{ alpha: true, antialias: false, stencil: false, depth: false }}
                style={{height: '100vh', zIndex: 1, pointerEvents: 'none'}}>
                <Suspense fallback={null}>
                    <Suzi />
                    <Environment files="adams_place_bridge_1k.hdr" />
                </Suspense>
                <ambientLight intensity={0.3} />
                <directionalLight position={[10, 10, 5]} />
                <directionalLight position={[-10, -10, -5]} />
                <directionalLight position={[-20, 0, -10]} intensity={0.7} />
                {renderOrbitControl()}
            </Canvas>
            <Loader/>
        </>
    );

    function renderOrbitControl(){
        return (
            <OrbitControls
                enableDamping
                enableZoom={false}
                enablePan={false}
                dampingFactor={0}
                rotateSpeed={.1}
                minPolarAngle={Math.PI / 3.5}
                maxPolarAngle={Math.PI / 1.5}/>
        );
    }
}