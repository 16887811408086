import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const StyledLoader = styled('div')`
    position: fixed;
    background: #212121;
    width: 100%;
    height: 100%;
    top: 1;
    left: 1;
    z-index: 101;
    opacity: 1;
`;

export default function Loader() {
    const [state, setstate] = useState(true);
    useEffect(() => {
        setTimeout(() => {setstate(false)}, 600);
    }, [])

    if (state) return (
        <StyledLoader></StyledLoader>
    )
    else return null
}
