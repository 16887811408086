import React, {useReducer, useMemo} from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import Header from './components/Header/Header'
import {
    PageHome
} from './pages';
import CanvasScene from './components/CanvasScene/CanvasScene';
import FollowMouse from './components/FollowMouse/FollowMouse';
import ConsoleLogAnimation from './components/ConsoleLogAnimation/ConsoleLogAnimation';
import WrapperModal from './components/Modal/Modal'
import ButtonModal from './components/ButtonModal/ButtonModal';
import BackgroundAnimation from './components/BackgroundAnimation/BackgroundAnimation';
import Loader from './components/Loader/Loader';
import KlaroCookieWindow from './components/KlaroCookieWindow/KlaroCookieWindow';
import CarouselReducer, { initialStateCarousel } from './hooks/reducers/carouselReducers';
import ModalReducer, { initialStateModal } from './hooks/reducers/modalReducers';
import { GlobalContext } from './hooks/context/Context';
import Theme from './config/theme'

function App() {
	const [carouselState, carouselDispatch] = useReducer(CarouselReducer, initialStateCarousel);
	const [modalState, modalDispatch] = useReducer(ModalReducer, initialStateModal);

	const value = useMemo(() => ({
        carousel: {state: carouselState, carouselDispatch: carouselDispatch},
		modal: {state: modalState, modalDispatch: modalDispatch},
    }), [carouselState, modalState]);

  	return (
		<GlobalContext.Provider value={value}>
			<Theme>
				<BrowserRouter>
					<Loader/>
					<ConsoleLogAnimation/>
					<Header />
					<CanvasScene />
					<FollowMouse/>
					<WrapperModal/>
					<ButtonModal/>
					<BackgroundAnimation/>
					<Switch>
						<Route path='/' exact component={PageHome}/>
					</Switch>
					<KlaroCookieWindow/>
				</BrowserRouter>
			</Theme>
		</GlobalContext.Provider>
  	);
}

export default App;
