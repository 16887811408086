import React, {useEffect} from 'react'
import styled, {keyframes} from 'styled-components'

const bananaFall = keyframes`
    from {
        top: -32px;
    }
    to {
        top: calc(100% + 24px);
    }
`;

const WrapperBackground = styled('div')`
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    top: 0;
    left: 0;
    pointer-events: none;

    .banana {
        animation: ${bananaFall} 0.5s ease-in both;
        position: absolute;
        top: 0;
        max-width: 30px;
    }
`;

export default function BackgroundAnimation() {

    useEffect(() => {
        handleBananasAnimation(60)
    })

    return (
        <WrapperBackground id='bananas'></WrapperBackground>
    )

    function handleBananasAnimation(count){
        const bananasNode = document.querySelector('#bananas')
        const divNode = document.createElement('div')
        let html = ''
        const bananas = document.getElementsByClassName("banana");

        for(let i = 0; i < bananas.length; i++){
            bananas[i].remove()
        }


        for (let i = 0; i < count; i++) {
            html = html +
                `<img class='banana'
                    style='
                    animation-delay: ${Math.random() * 1.5}s;
                    left: ${Math.random() * 100}%;
                    transform: rotate(${(Math.random() * 360) - 30}deg);
                    '
                    src='${process.env.PUBLIC_URL}/media/banana-bg.webp'
                />`
            }
        
        divNode.innerHTML = html
        bananasNode.appendChild(divNode)
    }
}
