import React, {useContext, useEffect, createRef} from 'react'
import styled from 'styled-components' 
import { GlobalContext } from '../../hooks/context/Context';
import Constants from '../../config/Constants'
import {TimelineMax} from 'gsap'

const WrapperModal = styled('div')`
    position: fixed;
    z-index: 10;
    top: 0%;
    right: 0vw;
    bottom: 0vh;
    width: 38%;
    min-width: 450px;
    background: #fff;
    display: grid;
    grid-template-columns: 6% 0% auto 6%;
    grid-template-rows: 20px 0vh auto 20vh 60px;

    @media ${'(max-width: 768px)'} {
        width: 100vw;
        left: 0vw;
        min-width: 250px;
    }
`

const WrapperDescriptionJob = styled('div')`
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 3;
    grid-row-end: auto;
    padding: 0px 20px;
    color: ${({theme}) => theme.colors.main};
    overflow-y: scroll;
    height: 100vh;
    ::-webkit-scrollbar {
        width: 1px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
        background: ${({theme}) => theme.colors.secondary};
    }

    h2 {
        color: ${({theme}) => theme.colors.secondary};
        font-size: 4em;
        font-weight: 900;
        transform: rotate(5deg);

        @media ${'(max-width: 768px)'} {
            font-size: 3em;
        }
    }

    h4 {
        color: ${({theme}) => theme.colors.secondary};
        font-size: 2em;
        font-weight: 700;
        transform: rotate(0deg) translateY(10px);
    }

    p {
        color: ${({theme}) => theme.colors.main};
        line-height: 150%;
    }

    li {
        margin-top: 10px;
    }

`;

const ButtonMailTo = styled('div')`
    margin: 80px 0px 140px 0px;

    a {
        width: fit-content;
        padding: 10px 25px;
        background: ${({theme}) => theme.colors.secondary};
        color: ${({theme}) => theme.colors.main};
        border-radius: 10px 2px 20px 2px;
        text-decoration: none;
        font-weight: 700;
        span {
            margin-left: 15px;
        }
    }
`;

export default function Modal() {
    const context = useContext(GlobalContext);
    const $refModal = createRef();

    useEffect(() => {
        handleAnimation()
    })

    if(context.modal.state.opened) return (
        <WrapperModal ref={$refModal}>
            <WrapperDescriptionJob>
                <h2>{Constants.ROLES[context.carousel.state.count].TITLE}</h2>
                <p dangerouslySetInnerHTML={{__html: Constants.ROLES[context.carousel.state.count].TEXT}}></p>
                {renderListSkill()}
            </WrapperDescriptionJob>
        </WrapperModal>
    )
    else return null

    function renderListSkill(){
        const skills = Constants.ROLES[context.carousel.state.count].SKILL_NEEDED;
        const list = skills.map((x, i) => {
            return <li key={i}>{x}</li>
        });
        return (
            <>
                <h4>Skills needed</h4>
                <ul>{list}</ul>
                <ButtonMailTo>
                    <a href={`mailto:info@youthquake.it?subject=YQ%20Internship%20-%20${Constants.ROLES[context.carousel.state.count].TITLE.replace(' ', '%20')}&body=Adesso%20trasforma%20questo%20spazio%20bianco%20in%20testo%2C%20vogliamo%20conoscerti%20meglio.%0D%0A%0D%0AAh%2C%20non%20dimenticarti%20di%20allegare%20anche%20un%20tuo%20CV%20aggiornato.`}>
                        Fatti notare <span aria-label="emojy" role="img">📨</span>
                    </a>
                </ButtonMailTo>
            </>
        )
    }

    function handleAnimation(){
        const tl = new TimelineMax({
            yoyo: false,
            repeat: 0,
        });

        tl
        .from($refModal.current, {
            duration: .5,
            y: '-100%',
            x: 400,
            skewX: 40,
            skewY: 40,
        })
        .to($refModal.current, {
            duration: 0,
            x: 0,
            skewX: 0,
            skewY: 0,
        })
    }
}
