import {useEffect} from 'react'

export default function ConsoleLogAnimation() {
    useEffect(() => {
        handleAnimationconsole()
    })

    return null;

    function handleAnimationconsole(){
        const styleTitle = [
            'width: 100%',
            'font-size: 2em',
            'font-weight: 700',
            'background: #0000ff',
            'color: #fff',
            'padding: 10px 20px',
            'line-height: 35px'
        ].join(';');

        const styleEmojy = [
            'width: 300px',
            'font-size: 1vw',
            'background: #fff',
            'padding: 10px 20px',
            'line-height: 35px'
        ].join(';');
        
        (function() {
            var h = ['🐒', '🐵', '🙈', '🙉', '🙊', '🍌'];
            var i = 0;
          
            return setInterval(() => {
              i = (i > h.length -1 ) ? 0 : i;
              console.clear();
              console.log('%c We go bananas for bananas! 🍌      ', styleTitle);
              console.log(`%c${h[i]} Bananas on bananas                                `, styleEmojy);
              const styleGiphy = [
                'background-image: url("https://media0.giphy.com/media/2fQGld4Q4znRGma6t8/giphy.gif?cid=ecf05e47aws6zsjq4uzgpowdmnsrjopipwdbrch10m4mjf3f&rid=giphy.gif&ct=g")',
                'background-size: cover',
                'color: #fff',
                'padding: 110px 20px',
                'line-height: 35px',
                'width: 100%',
                ].join(';');
              console.log('%c                                                                                ', styleGiphy);
              i++;
            }, 800);
          })();
    }
}
