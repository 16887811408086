import React, {useEffect} from 'react'
import { useGLTF } from '@react-three/drei'
import useTransmissionMaterial from './use-material'
import usePostprocessing from './use-postprocessing'
import gsap from 'gsap'

export function Suzi() {
	const $refGroup = React.createRef()

	useEffect(() => {
			try {
				document.onmousemove = (e) => handleMouseMove(e);

				const snd = new Audio(`${process.env.PUBLIC_URL}/media/yq-monkey.wav`);
				snd.volume = .2
				snd.play();

				gsap.to($refGroup.current.rotation, {
					duration: 1,
					y: Math.PI * 2,
					x: Math.PI * 2,
					ease: "power3.inOut"
				});

				gsap.to($refGroup.current.position, {
					duration: 1, y: 1, ease: "power3.inOut"
				})
				gsap.to($refGroup.current.position, { 
					duration: 1, y: 0, delay: .5, ease: "power3.inOut"
				})

				if(window.innerWidth < 1190) {
					gsap.to($refGroup.current.scale, { 
						duration: 0,
						y: .6,
						x: .6,
						z: .6
					})	
				}
				else {
					gsap.to($refGroup.current.scale, { 
						duration: 0,
						y: 1,
						x: 1,
						z: 1
					})	
				}
			} catch (error) {
				console.log(error)
			}
	})


	const { nodes } = useGLTF('/suzanne-draco.glb')
  	const [ref, pipeline] = useTransmissionMaterial({
		frontMaterial: { color: '#ffffff' },
		backMaterial: { color: '#efefef' },
		transmissionMaterial: {
			transmission: 1,
			transmissionIntensity: 2.5,
			distortionIntensity: 0.75,
			fresnel: 3,
			fresnelAmplifier: 2
    	}
  	})
  	usePostprocessing(pipeline)
  	return (
	  <group ref={$refGroup} rotation={[0,0,0]}>
		  <mesh ref={ref} geometry={nodes.Suzanne.geometry} />
	  </group>
	);

	function handleMouseMove(event){
		const mouseX = ( event.clientX / window.innerWidth ) - .5;
  		const mouseY = ( event.clientY / window.innerHeight ) - .5;
		if ($refGroup && $refGroup.current && $refGroup.current.rotation) {
			gsap.to($refGroup.current.rotation, {
				duration: 0,
				y: mouseX,
				x: mouseY
			});
		}
	}
}
