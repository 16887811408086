
export const initialStateModal = {
    opened: false
};

const ModalReducer = (state, action) => {
    switch (action.type) {
        case 'TOGGLE':
            return { ...state, opened: !state.opened};
        default:
            return state;
    }
};

export default ModalReducer;