import React, {useEffect, useContext} from 'react'
import styled, {keyframes} from 'styled-components'
import gsap, {TimelineMax} from 'gsap'
import { GlobalContext } from '../../hooks/context/Context';

const rotate = keyframes`
    to {
        transform: rotate(360deg);
    }
`

const WrapperButtonModal = styled('div')`
    position: fixed;
    bottom: 8vh;
    left: 75vw;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 250px;
    height: 250px;

    .anm-b {
        animation: ${rotate} 90s linear infinite;
    }

    .anm-a {
        animation: ${rotate} 22s linear infinite;
    }

    @media ${'(max-width: 768px)'} {
        width: 120px;
        height: 120px;
        top: 0;
        bottom: auto;
        margin-top: -20px;
    }

    svg {
        fill: ${({theme}) => theme.colors.secondary};
        stroke: ${({theme}) => theme.colors.secondary};
        max-width: 250px;
        width: 100%;
        position: absolute;
        &:last-child{
            transform: rotate(90deg);
            opacity: .2;
        }
    }
    span {
        position: absolute;
        color: ${({theme}) => theme.colors.main};
        font-weight: 700;
        transform: rotate(-15deg);
        position: absolute;
        z-index: 3;

        @media ${'(max-width: 768px)'} {
            font-size: 8px;
        }
    }

`;

export default function ButtonModal() {
    const context = useContext(GlobalContext)
    const $ref = React.createRef();
    const blobPathStart = 'M44.2,-74.9C58.4,-68.4,71.7,-58.8,79.7,-45.8C87.7,-32.8,90.3,-16.4,88.8,-0.9C87.2,14.6,81.7,29.3,74.2,43.2C66.8,57.2,57.6,70.4,44.9,75.7C32.2,81,16.1,78.2,0.3,77.7C-15.5,77.1,-30.9,78.7,-43.8,73.6C-56.8,68.5,-67.2,56.7,-72.3,43.3C-77.4,29.9,-77.1,14.9,-77,0C-76.9,-14.9,-77.1,-29.7,-71.1,-41.5C-65.1,-53.3,-53,-62,-40.1,-69.4C-27.2,-76.7,-13.6,-82.6,0.7,-83.8C15,-85,30,-81.5,44.2,-74.9Z';
    const blobPathMiddle = 'M40.8,-70.3C54.5,-62.8,68.4,-55.2,78,-43.4C87.7,-31.7,93,-15.8,93.7,0.4C94.3,16.6,90.2,33.1,80.4,44.6C70.7,56.2,55.3,62.6,40.9,70.3C26.6,78,13.3,87,0,86.9C-13.3,86.9,-26.5,77.9,-38.1,68.6C-49.7,59.3,-59.7,49.8,-69.5,38.3C-79.2,26.8,-88.7,13.4,-90.3,-0.9C-92,-15.3,-85.7,-30.6,-77.5,-44.7C-69.2,-58.8,-59.1,-71.7,-45.8,-79.5C-32.6,-87.3,-16.3,-90,-1.4,-87.7C13.6,-85.3,27.1,-77.9,40.8,-70.3Z';
    const blobPathEnd = 'M38.1,-69.5C49.3,-59.6,58.1,-49.1,67.9,-37.4C77.7,-25.8,88.4,-12.9,88.8,0.2C89.2,13.3,79.2,26.7,70.1,39.5C61,52.3,52.7,64.6,41.1,71.4C29.6,78.2,14.8,79.4,-0.1,79.6C-15,79.7,-30,78.8,-41.2,71.8C-52.4,64.9,-59.8,51.8,-68.9,38.9C-78.1,25.9,-88.9,12.9,-89.7,-0.5C-90.6,-13.9,-81.4,-27.8,-73,-42C-64.5,-56.2,-56.9,-70.7,-44.8,-80.1C-32.8,-89.5,-16.4,-93.8,-1.5,-91.2C13.5,-88.7,26.9,-79.4,38.1,-69.5Z';

    useEffect(() => {
        handleAnimationButton();
        if(context.modal.state.opened && window.innerWidth > 767) {
            gsap.to('#btn-wrapper', {left: '90vw', y: '12vh', scale: .6, duration: .2})
        }
        else {
            gsap.to('#btn-wrapper', {left: '75vw', y: 0, scale: 1, duration: .2})
        }
    })

    return (
        <WrapperButtonModal
            id={'btn-wrapper'}
            onClick={() => context.modal.modalDispatch({type: 'TOGGLE'})}>
                <i style={{display: 'contents'}}>
                    <span className="anm-b">{
                        !context.modal.state.opened ? 'DISCOVER MORE': 'CLOSE'
                    }</span>
                    {renderSVG('anm-a')}
                    {renderSVG('')}
                </i>
        </WrapperButtonModal>
    )

    function renderSVG(className){
        return (
            <svg className={className} viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                <path ref={$ref} d="M44.2,-74.9C58.4,-68.4,71.7,-58.8,79.7,-45.8C87.7,-32.8,90.3,-16.4,88.8,-0.9C87.2,14.6,81.7,29.3,74.2,43.2C66.8,57.2,57.6,70.4,44.9,75.7C32.2,81,16.1,78.2,0.3,77.7C-15.5,77.1,-30.9,78.7,-43.8,73.6C-56.8,68.5,-67.2,56.7,-72.3,43.3C-77.4,29.9,-77.1,14.9,-77,0C-76.9,-14.9,-77.1,-29.7,-71.1,-41.5C-65.1,-53.3,-53,-62,-40.1,-69.4C-27.2,-76.7,-13.6,-82.6,0.7,-83.8C15,-85,30,-81.5,44.2,-74.9Z" transform="translate(100 100)" />
            </svg>
        )
    }

    function handleAnimationButton(){
        const tl = new TimelineMax({
            yoyo: true,
            repeat: -1,
            ease: 'linear'
          });
        tl
        .to($ref.current, {
            duration: 1,
            attr: {d: blobPathStart},
        })
        .to($ref.current, {
            duration: 1,
            attr: {d: blobPathEnd},
        })
        .to($ref.current, {
            duration: 1,
            attr: {d: blobPathMiddle},
        })
        .to($ref.current, {
            duration: 1,
            attr: {d: blobPathStart},
        })
    }
}
